import {useAuth} from "../../../services/Auth";
import {Link, useHistory, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import AdminPageHeader from "../../../layouts/components/AdminPageHeader";
import { Col, Form, Row, Button } from 'react-bootstrap';
import LOCATION from '@/constants/Location';

function SubscriptionManagement() {
  const auth = useAuth()
  const history = useHistory()
  const route = useLocation()
  const {t} = useTranslation();

  const [products, setProducts] = useState([]);

    useEffect(() => {
        auth.getRequest(LOCATION.SUBSCRIPTION_PRODUCTS.API.path)
          .then(({data}) => {
            setProducts(data.data)
          })
          .catch(error => {
            console.log(error)
          })
    }, []);

    const toggleAllowTrial = (product) => {
        auth.putRequest(LOCATION.SUBSCRIPTION_PRODUCTS.API.TOGGLE_TRIAL(product.id))
          .then(response => {
            product.allow_trial = !product.allow_trial
            setProducts([...products])
          })
          .catch(error => {
            console.log(error)
          })
    }

    const handleDeletePromotion = (promotion) => () => {
        auth.deleteRequest(`/subscription-product-promotions/${promotion.id}`)
          .then(response => {
            const product = products.find(product => product.id === promotion.subscription_product_id)
            const index = product?.subscription_product_promotions?.findIndex(p => p.id === promotion.id)
            product?.subscription_product_promotions?.splice(index, 1)
            setProducts([...products])
          })
          .catch(error => {
            console.log(error)
          })
    }

  return (
    <div className="py-2 px-4">
      <Helmet>
        <title>{t('pages.subscription_management.title')} - {t('common.app')}</title>
      </Helmet>
      <div className="my-4 d-flex justify-content-between">
        <AdminPageHeader
          mainTitle={t('pages.subscription_management.title')}
        />
      </div>
      <div className="my-4 bg-white">
          {
              products?.map((product, index) => (
                  <Row key={index} className="border-bottom py-3">
                    <Col md={12}>
                        <h5>{product.name}</h5>
                    </Col>
                    <Col md={3}>
                        <p>Price: {product.price / 100}</p>
                    </Col>
                    <Col md={3}>
                      <p>Currency: {product.currency}</p>
                    </Col>
                    <Col md={3}>
                        <p>Allow Trial: {product.allow_trial ? 'Yes' : 'No'}</p>
                        <Button
                            variant={product.allow_trial ? 'danger' : 'success'}
                            onClick={() => toggleAllowTrial(product)}
                        >
                            {product.allow_trial ? 'Disable' : 'Enable'}
                        </Button>
                    </Col>
                    <Col md={12}>
                        <Link to={`/subscription-products/${product.id}/subscription-product-promotions/create`} className="btn btn-primary">
                            Create Promotion
                        </Link>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Code</th>
                                    <th>Interval</th>
                                    <th>Frequency</th>
                                    <th>Price</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    product.subscription_product_promotions?.map((promotion, index) => (
                                        <tr key={index}>
                                            <td>{promotion.code}</td>
                                            <td>{promotion.interval}</td>
                                            <td>{promotion.frequency}</td>
                                            <td>{promotion.price}</td>
                                            <td>
                                                <Button
                                                    variant="danger"
                                                    onClick={handleDeletePromotion(promotion)}
                                                >
                                                    Delete
                                                </Button>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </Col>
                  </Row>
              ))
          }
      </div>
    </div>
  )
}

export default SubscriptionManagement;